const SUPPORT_URL = "https://engage.benchmarkit.solutions/#/clinicians-meeting";
var ATList = [];
var regularList = [];
var recording;
var countStream = 0;
var formComponent = null;
var msg = '';
var room = 0;
var presentationStarted = false;
var ischatViewOpen = false;
var isShareScreenHidden = false;
var options = {
  id: "vcx_1001",
  attachMode: "",
  player: {
    'autoplay': '',
    'name': '',
    'nameDisplayMode': '',
    'frameFitMode': 'bestFit',
    'skin': 'classic',
    'class': '',
    'height': "253px",
    'width': '353px',
    'minHeight': '120px',
    'minWidth': '160px',
    'aspectRatio': '',
    'volume': 0,
    'media': '',
    loader: {
      show: false,
      url: "assets/img/loader.gif",
      style: "default",
      class: "",
    },
  },
  toolbar: {
    displayMode: "auto",
    autoDisplayTimeout: 0,
    position: "top",
    skin: "default",
    iconset: "default",
    class: "",
    buttons: {
      play: false,
      share: false,
      mic: false,
      resize: false,
      volume: false,
      mute: false,
      record: false,
      playtime: false,
      zoom: false,
    },
    branding: {
      display: false,
      clickthru: "https://engage.benchmarkit.solutions/#/login",
      target: "new",
      logo: "assets/img/color-logo.png",
      title: "Engage Therepy",
      position: "right",
    },
  },
};
var config = {
  audio: true,
  video: true,
  data: true,
  // videoSize: [320, 180, 640, 480],
  videoSize: [],
  attributes: {
    name: name,
  },
  options: options,
};

var reConnectOpt = {
  "allow_recnnect": true,
  "number_of_attempts": 3,
  "timeout_interval": 10000
};

var setLiveStream = function (stream, userName) {
  // Listening to Text Data
  // console.log(stream)
  stream.addEventListener("stream-data", function (e) {
    var text = e.msg.textMessage;
    var html = $("#multi_text_container_div").html();
    $("#multi_text_container_div").html(html + text + "<br>");
  });

  var parentCont = document.getElementById("multi_video_container_div");
  var controlsDiv = document.getElementById("controls");

  if (!stream.local) {
    var newStreamDiv = document.createElement("div");
    newStreamDiv.setAttribute("id", "liveStream_" + countStream);
    newStreamDiv.setAttribute('class', 'live_stream_div');
    // newStreamDiv.setAttribute("class", "live_stream_div");
    newStreamDiv.style.width = "100%";
    newStreamDiv.style.height = "100%";

    document
      .getElementById("multi_video_container_div")
      .appendChild(newStreamDiv);
    // options.player.height = "inherit";
    // options.player.width = "inherit";
    stream.show("liveStream_" + countStream, options);

    // if (userName !== "") {
    //   var node = document.createElement("div");
    //   node.innerHTML = userName;
    //   node.classList.add("name-div");
    //   document.getElementById("multi_video_container_div").appendChild(node);
    // }

    countStream++;
  } else {
    var localDiv = document.getElementById("local_video_div");
    //username = stream.getAttributes().name;
    options.player.loader.class = "";
    options.player.loader.show = false;
    stream.show("local_video_div", options);
    // var node = document.createElement("div");
    // node.innerHTML = username;
    // node.classList.add("name-div");
    // document.getElementById("local_video_div").appendChild(node);
    // localDiv.style.width = `${parentCont.clientWidth}px`;
    // localDiv.style.height = `${parentCont.clientHeight}px`;
    // localDiv.style.width = (window.innerWidth / 2) + "px";
    // localDiv.style.height = `${window.innerHeight -
    //   controlsDiv.clientHeight}px`;
    localDiv.style.width = `15%`;
    localDiv.style.height = `15%`;
    localDiv.style.overflow = `hidden`;
  }
};
function copyUrl() {

  formComponent.urlCopyMsg('URL Copied');
}

function joinRoomlocal(tokenData, regularList) {
  localStream = EnxRtc.joinRoom(tokenData, config, function (
    success,
    error
  ) {
    if (error && error != null) {
      console.log("ERROR" + error.message);
      console.log(error);
      // alert(error.type + "jjjj");
      if (error.type == "media-access-denied") {
        status_flag = true;
          formComponent.userClinicianRoomUpdate(status_flag);
        msg = "Not able to join a meeting due to media access denied."
        // alert("kkkkkkkkkk access denied");
      } else if (error.type == "room-error") {
        msg = "You left from the meeting. Please rejoin."
        // document.getElementById("hide_clinician_video_message").classList.remove("hidec");
        // alert(error.type + " Client not able to join meeting due to media access denied.");
      } else {
        msg = "There is a some interruption to join a meeting.Please try again."
      }
      // var elem = document.getElementById("hide_clinician_video_message");
      // // alert(elem + "////");
      // // alert(elem.classList + "...????");
      // // alert(elem.classList.remove("hidec") + "///>>>>>>>>>");
      //   elem.classList.remove("hidec");
      //   endCall();
      formComponent.errorHandling(msg);
    }
    if (success && success != null) {
      // console.log(success)

      room = success.room;
      var ownId = success.publishId;
      setLiveStream(localStream);
      for (var i = 0; i < success.streams.length; i++) {
        room.subscribe(success.streams[i]);
      }

      status_flag = false;
      formComponent.userClinicianRoomUpdate(status_flag);

      // Active Talker list is updated
      room.addEventListener("active-talkers-updated", function (event) {

        // alert(event + ".....event");
        ATList = event.message.activeList;
        // console.log(ATList, 'ATList');
        // console.log(room.me.role, 'room.me.role');
        if (room.me.role == 'participant' && !(ATList.length)) {
          formComponent.waitingRoomUpdate(1);
        }
        if (room.me.role == 'moderator' && (ATList.length)) {
          formComponent.UpdateRegularGame();
          // alert(room.me.role + "//room.me.role//" + ATList.length);
        }
        if (room.me.role == 'participant' && (ATList.length)) {
          formComponent.doAngularThing();
        }
        console.log(ATList.length)
        if (ATList.length) {
          // alert(ATList.length + ".....length");
          // var elem = document.getElementById("hide_clinician_video_message");
          // elem.classList.add("hidec");
          // formComponent.userRoomUpdate();
          status_flag = false;
          formComponent.userClinicianRoomUpdate(status_flag);
        }else {
            // alert(ATList.length + ".....lengthiiiiiiiii");
            // var elem = document.getElementById("hide_clinician_video_message");
            // elem.classList.remove("hidec");
            // alert(elem.classList + ".....elem.classList");
            status_flag = true;
          formComponent.userClinicianRoomUpdate(status_flag);
            // formComponent.userClinicianRoomUpdate();
          }

        // if (ATList.length == 0) {
        //   alert(ATList.length + ".....lengthiiiiiiiii");
        //   var elem = document.getElementById("hide_clinician_video_message");
        //   elem.classList.add("hidec");
        //   formComponent.userRoomUpdate();
        // }

        var video_player_len = document.querySelector(
          "#multi_video_container_div"
        ).childNodes;
        if (
          event.message &&
          event.message !== null &&
          event.message.activeList &&
          event.message.activeList !== null
        ) {
          if (ATList.length == video_player_len.length) {
            return;
          } else {
            document.querySelector("#multi_video_container_div").innerHTML = "";
            for (stream in room.remoteStreams.getAll()) {
              var st = room.remoteStreams.getAll()[stream];
              for (j = 0; j < ATList.length; j++) {
                if (ATList[j].streamId == st.getID()) {
                  setLiveStream(st, ATList[j].name);
                }
              }
            }
          }
        }
        // console.log("Active Talker List :- " + JSON.stringify(event));


      });

      // Stream has been subscribed successfully
      room.addEventListener("stream-subscribed", function (streamEvent) {
        var stream =
          streamEvent.data && streamEvent.data.stream
            ? streamEvent.data.stream
            : streamEvent.stream;
        for (k = 0; k < ATList.length; k++) {
          if (ATList[k].streamId == stream.getID()) {
            setLiveStream(stream, ATList[k].name);
          }
        }
      });

      // Listening to Incoming Data
      room.addEventListener("active-talker-data-in", function (data) {
        //  console.log("active-talker-data-in" + data);
        var obj = {
          msg: data.message.message,
          timestamp: data.message.timestamp,
          username: data.message.from,
        };
        // Handle UI to  display message
        // console.log(obj)
      });

      // Stream went out of Room
      room.addEventListener("stream-removed", function (event) {
        //  console.log(event);
      });

      // Notification recording started to all
      room.addEventListener("room-record-on", function (event) {
        // Recording started, Update UI
        // event.message.moderatorId = Moderator who stated recording.
        // document.getElementById("multi_record").innerText= "Hide Filter";
        document.getElementById("hiddenCallRecordFlag").value = "1";
        var elem = document.getElementById("recording");
        elem.classList.remove("hide");
        // console.log(event.message);
      });


      room.addEventListener("room-record-off", function (event) {
        // Recording stopped, Update UI
        // event.message.moderatorId = Moderator who stopped recording.

        // document.getElementById("multi_record").innerText= "show Filter";
        var elem = document.getElementById("recording");
        elem.classList.add("hide");

      });

      room.addEventListener("room-connected", function (event) {
        // Connected. event receives Room Meta JSON
        console.log(event + "room connected");
      });

      room.addEventListener("room-failed", function (error) {
        // Connection failed. Find error
        console.log(error);
      });

      room.addEventListener("user-connected", function (event, user) {
        // A new user connected. user JSON has user information
        // console.log(event);

        // var elem = document.getElementById("hidenotify");
        // elem.classList.add("hide");
        /////////////////////////if moderator enters in room update game list /////////////
        if (event.role == "moderator") {
          formComponent.doAngularThing();
        }
        /////////////////////////end here//////////////////////
      });

      room.addEventListener("room-disconnected", function (event) {
        // You are disconnected
        console.log(event +"...disconnected")
      });

      room.addEventListener("user-disconnected", function (event) {
        // One user is disconnected
        // event - User Information of disconnected user
        console.log(event, 'event.role', event.role);
        if (event.role == 'participant') {
          msg = event.name + " left the meeting."
          formComponent.endCallData();
        } else {
          msg = event.name + " left the meeting."
        }
        //formComponent.userDisconnnects(msg);
        formComponent.userDisconnnects(msg, event.role);
        console.log(event +"...user-disconnected");
      });

      // Listen to event to know when Extension Window is open
      // room.addEventListener('conference-remaining-duration', function(event) {
      //   console.log(event);
      //   var timeLeft = event.message.timeLeft;
      //   alert(timeLeft)
      //   if(timeLeft == 1){
      //     this.extendcall();
      //   }
      //   // Show UI to all usersr or to participant to
      // // trigger EXTEND by caling method.
      // });

      room.addEventListener('conference-expiring-duration', function (event) {
        console.log(event)
        var timeLeft = event.message.timeLeft;
        if (timeLeft == 5) {
          formComponent.callExpiring();
        }
        // Show UI to all usersr or to participant to
        // trigger EXTEND by caling method.
      });

      room.getTalkerCount(function (response) {
        // Talker info in response JSON:
        // { "result": 0, "maxTalkers": 4 }
      });
      room.addEventListener("share-started", function (event) {
        // Get Stream# 11 which carries Screen Share
        var shared_stream = room.remoteStreams.get(101);
        remoteOptions = {
          player: {
            height: "100%",
            width: "100%",
          },
          resizer: false,
          toolbar: {
            displayMode: false,
          },
        };
        if (typeof streamShare == 'undefined' || streamShare == null) {
          shared_stream.play("showScreen", remoteOptions);
          // if(typeof streamShare !== 'undefined')
          //   streamShare.play("showScreen", remoteOptions);
          presentationStarted = true;
          document.getElementById('hideScreenShare').classList.remove("d-none");
          document.getElementById('share_screen_btn').classList.add("d-none");
          document.getElementById('showScreen').classList.add("d-block");
          document.getElementById('showScreen').classList.remove("d-none");
          document.getElementById('stick_to_top').classList.add("stick-top");
          document.getElementById('local_video_div').classList.add("stick-top");
          room.addEventListener("share-stopped", function (event) {
            presentationStarted = false;
            streamShare = null;
            document.getElementById('fixed-social').classList.add("d-block");
            document.getElementById('hideScreenShare').classList.add("d-none");
            document.getElementById('unhideScreenShare').classList.add("d-none");
            document.getElementById('share_screen_btn').classList.remove("d-none");
            document.getElementById('showScreen').classList.remove("d-block");
            document.getElementById('showScreen').classList.add("d-none");
            document.getElementById('stick_to_top').classList.remove("stick-top");
            document.getElementById('local_video_div').classList.remove("stick-top");
          });
        }
      });
      room.addEventListener("message-received", function (data) {
        var obj = {
            msg: data.message.message,
            timestamp: data.message.timestamp,
            username: data.message.sender,
        };
        if (!ischatViewOpen) {
            document.getElementById('chatOn').classList.add("notification");
        }
        plotChat(obj);
      });

    }

  });

}


function shareScreen() {

  if (
    navigator.userAgent.indexOf("QQBrowser") > -1 &&
    room.Connection.getBrowserVersion() < 72
  ) {
    toastr.error(language.ss_unsupport_qq);
    return;
  } else if (
    navigator.userAgent.indexOf("Chrome") > -1 &&
    room.Connection.getBrowserVersion() < 72
  ) {
    toastr.error(language.ss_unsupport_chrome_below72);
    return;
  }
  presentationStarted = false;
  if (presentationStarted === false) {
    desktop_shared = true;
    streamShare = room.startScreenShare(function (rs) {
      if (rs.result === 0) {
        presentationStarted = true;
        document.getElementById("share_screen_btn").classList.add("hide");
        document.getElementById("unshare_screen_btn").classList.remove("hide");
        document.getElementById('fixed-social').classList.add("d-block");
        document.getElementById('hideScreenShare').classList.add("d-none");
        document.getElementById('unhideScreenShare').classList.add("d-none");
        document.getElementById('showScreen').classList.remove("d-block");
        document.getElementById('showScreen').classList.add("d-none");
        document.getElementById('stick_to_top').classList.remove("stick-top");
        document.getElementById('local_video_div').classList.remove("stick-top");
      } else if (rs.result === 1151) {
        desktop_shared = false;
        toastr.error(rs.error);
      } else if (rs.result === 1144) {
        desktop_shared = false;
        toastr.error(rs.error);
      } else if (rs.result === 1150) {
        desktop_shared = false;
        // $("#extension-dialog").modal("toggle");
      } else {
        desktop_shared = false;
        alert("Screen share not supported");
      }
    });
  }
}

function hideshareScreen() {
  if (!isShareScreenHidden) {
    isShareScreenHidden = true;
    document.getElementById('showScreen').classList.remove("d-block");
    document.getElementById('showScreen').classList.add("d-none");
    document.getElementById('stick_to_top').classList.remove("stick-top");
    document.getElementById('local_video_div').classList.remove("stick-top");
    document.getElementById("hideScreenShare").classList.add("d-none");
    document.getElementById("unhideScreenShare").classList.remove("d-none");
  } else {
    isShareScreenHidden = false;
    document.getElementById('showScreen').classList.remove("d-none");
    document.getElementById('showScreen').classList.add("d-block");
    document.getElementById('stick_to_top').classList.add("stick-top");
    document.getElementById('local_video_div').classList.add("stick-top");
    document.getElementById("hideScreenShare").classList.remove("d-none");
    document.getElementById("unhideScreenShare").classList.add("d-none");
  }
}

function openChat(){
  document.getElementById('chatOn').classList.remove("notification");;
  var chatDiv = document.getElementById('chatScreen');
  var chatOn = document.getElementById('chatOn');
  var chatOff = document.getElementById('chatOff');
  if(!ischatViewOpen){
      chatDiv.classList.remove("d-none");
      chatOn.classList.add("hide");
      chatOff.classList.remove("hide");
      ischatViewOpen = true;
  }else{
      chatDiv.classList.add("d-none");
      chatOn.classList.remove("hide");
      chatOff.classList.add("hide");
      ischatViewOpen = false;
  }
}
function sendMessage(msg){

  if(msg == '' || msg == null)
    return false;
  room.sendMessage(msg, true, [], function () {});
  plotChat({msg,username:room.me.name})
  return true;
}

function plotChat(obj){
  var chatDiv = document.getElementById("messageArea");
  var msgbody = document.createElement("div");
  msgbody.classList.add("messagebody");
  var ptag = document.createElement("p");
  ptag.classList.add('mb-0');
  ptag.classList.add('sender-name');
  ptag.innerHTML = obj.username.split(' ')[0];
  var msgTimeStamp = document.createElement("span");
  msgTimeStamp.classList.add('date-time');
  msgTimeStamp.innerHTML = formatAMPM(new Date());
  var msg = document.createElement("p")
  msg.innerHTML = obj.msg;
  msgbody.appendChild(ptag);
  msgbody.appendChild(msg);
  chatDiv.appendChild(msgbody);
  ptag.appendChild(msgTimeStamp);
}

function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

function enableBtn(){

  cntOfMsg = document.getElementById("chatmsg").value.trim();
  chatBtn = document.getElementById("chatBtn");
  if(cntOfMsg.length > 0){
    chatBtn.classList.add("btn-link-active");
    chatBtn.removeAttribute('disabled');
  } else {
    chatBtn.classList.remove("btn-link-active");
    chatBtn.disabled = true;
  }
}

function unshareScreen() {
  room.stopScreenShare(function (res) {
    // if (res.result == 0) {
    presentationStarted = false;
    streamShare = null;
    document.getElementById("share_screen_btn").classList.remove("hide");
    document.getElementById("unshare_screen_btn").classList.add("hide");
    // }
  });
}
function audioMute() {
  localStream.muteAudio(function (arg) {
    var elem = document.getElementById("audioOn");
    elem.classList.add("hide");
    var element = document.getElementById("audioOff");
    element.classList.remove("hide");
  });
}

function audioUnMute() {
  localStream.unmuteAudio(function (arg) {
    var elem = document.getElementById("audioOn");
    elem.classList.remove("hide");
    var element = document.getElementById("audioOff");
    element.classList.add("hide");
  });
}

function audioUnMuteoriginal() {
  var elem = document.getElementsByClassName("icon-confo-mute")[0];
  var onImgPath = "../assets/img/mike.png",
    onImgName = "mike.png";
  var offImgPath = "../assets/img/mute-mike.png",
    offImgName = "mute-mike.png";
  var currentImgPath = elem.src.split("/")[elem.src.split("/").length - 1];
  if (currentImgPath === offImgName) {
    localStream.unmuteAudio(function (arg) {
      elem.src = onImgPath;
      elem.title = "mute audio";
    });
  } else if (currentImgPath === onImgName) {
    localStream.muteAudio(function (arg) {
      elem.src = offImgPath;
      elem.title = "unmute audio";
    });
  }
}

function videoMuteOriginal() {
  var elem = document.getElementsByClassName("icon-confo-video-mute")[0];
  var onImgPath = "../assets/img/video.png",
    onImgName = "video.png";
  var offImgPath = "../assets/img/mute-video.png",
    offImgName = "mute-video.png";
  var currentImgPath = elem.src.split("/")[elem.src.split("/").length - 1];
  if (currentImgPath === offImgName) {
    localStream.unmuteVideo(function (res) {
      var streamId = localStream.getID();
      var player = document.getElementById("stream" + streamId);
      player.srcObject = localStream.stream;
      player.play();
      elem.src = onImgPath;
      elem.title = "mute video";
    });
  } else if (currentImgPath === onImgName) {
    localStream.muteVideo(function (res) {
      elem.src = offImgPath;
      elem.title = "unmute video";
    });
  }
}

function videoMute() {
  
  localStream.muteVideo(function (res) {
    var elem = document.getElementById("videoUnMute");
    elem.classList.add("hide");
    var element = document.getElementById("videoMute");
    element.classList.remove("hide");
  });

  // var role = document.getElementById("current_role").value;
  // alert('mute video'+role);
  // document.getElementsByClassName("hide_client_video_message").style.color = "red";
  // document.getElementsByClassName("hide_clinician_video_message").style.color = "red";

  // if(role == "moderator")
  // {
  //    // document.getElementsByClassName("hide_client_video_message").style.color = "red";
  //     //      var elem = document.getElementsByClassName("hide_clinician_video_message");
  //     //     //  elem.classList.remove("hide");
  //       var elem = document.getElementById("hide_clinician_video_message");
  //         elem.classList.add("hidec");
  //     alert(elem + "...elem");
  //     //   elem.setAttribute("disabled", "false");

  //     // document.getElementById("hide_clinician_video_message").disabled = true;
  //     // console.log('mute video hide_clinician_video_message '+role);
  // }else{
  //   // var elem = document.getElementsByClassName("hide_client_video_message");
  //   // elem.classList.remove("hide");
  //   var elem = document.getElementById("hide_client_video_message");
  //   elem.classList.add("hidec");
  //   alert(elem + "...elem");
  //   // elem.setAttribute("disabled", "false");
  //   // document.getElementById("hidenotify").disabled = false;
  //   // document.getElementById("hide_client_video_message").disabled = true;
  //   // console.log('mute video hide_client_video_message '+role);

  // }

  // if(role == "moderator")
  // {
  //   var elem = document.getElementById("hide_clinician_video_message");
  //   elem.classList.add("hidec");
  //   // var elem = document.getElementById("hide_client_video_message");
  //   // elem.classList.add("hidec");
  //   // document.getElementById("hidenotify").disabled = true;
  //   // var elem = document.getElementById("hide_client_video_message");
  //   // elem.classList.remove("hidec");
  //   alert(elem + "...elem");
  //   // document.getElementById("hide_clinician_video_message").disabled = false;
  //   // console.log('unmute video hide_clinician_video_message '+role);
  // }else{
  //   // var elem = document.getElementsByClassName("hide_client_video_message");
  //   // elem.classList.remove("hide");
  //   // var elem = document.getElementById("hide_clinician_video_message");
  //   // elem.classList.remove("hidec");
  //   var elem = document.getElementById("hide_client_video_message");
  //   elem.classList.remove("hidec");
  //   // document.getElementById("hide_client_video_message").disabled = false;
  //   // console.log('unmute video hide_client_video_message '+role);
  //   alert(elem + "...elem");
  // }
}

function videoUnMute() {
  // console.log('mute video');
  // var role = document.getElementById("current_role").value;
  // alert('unmute video'+role);

  // if(role == "moderator")
  // {
  //   // var elem = document.getElementById("hide_clinician_video_message");
  //   // elem.classList.add("hide");
  //   var elem = document.getElementById("hide_client_video_message");
  //   elem.classList.remove("hidec");
  //   // document.getElementById("hidenotify").disabled = true;
  //   var elem = document.getElementById("hide_clinician_video_message");
  //   elem.classList.add("hidec");
  //   alert(elem + "...elem");
  //   // document.getElementById("hide_clinician_video_message").disabled = false;
  //   // console.log('unmute video hide_clinician_video_message '+role);
  // }else{
  //   // var elem = document.getElementsByClassName("hide_client_video_message");
  //   // elem.classList.remove("hide");
  //   var elem = document.getElementById("hide_clinician_video_message");
  //   elem.classList.add("hidec");
  //   var elem = document.getElementById("hide_client_video_message");
  //   elem.classList.remove("hidec");
  //   // document.getElementById("hide_client_video_message").disabled = false;
  //   // console.log('unmute video hide_client_video_message '+role);
  //   alert(elem + "...elem");
  // }
  localStream.unmuteVideo(function (res) {
    var streamId = localStream.getID();
    // var player = document.getElementById("stream" + streamId);
    // player.srcObject = localStream.stream;
    // player.play();
    var elem = document.getElementById("videoMute");
    elem.classList.add("hide");
    var element = document.getElementById("videoUnMute");
    element.classList.remove("hide");
  });
}

function stopGame() {
  //document.getElementById( 'changelink' ).setAttribute( 'src', '' );
  var frame = document.getElementById('changelink');
  frame.src = "about:blank";
  formComponent.doAngularThing();
}

function endCall() {
  console.log(ATList);
  console.log(room);
  // alert(room + ":::room:::");
  // navigator.getUserMedia({audio: false, video: true},
  //     function(stream) {
  //             // can also use getAudioTracks() or getVideoTracks()
  //         var track = stream.getTracks()[0];  // if only one media track
  //         // ...
  //         console.log(">>>>streamstream>>>>"+track.enabled);
  //         // track.stop();
  //         track.enabled = false;
  //         console.log(stream.getTracks()[0].enabled + ">>>>>>>webcam");

  //         stream.getTracks().forEach(function(track) {
  //             track.stop();
  //           });
  //     },
  //     function(error){
  //         console.log('getUserMedia() error', error);
  //     });

      // if (localStream) {
      //   localStream.getTracks().forEach(track => track.stop());
      //   localStream = null;
      //   setState({ playing: false });
      //  }
      // navigator.getUserMedia({audio: false, video: true},
      //   function(stream) {
      //           // can also use getAudioTracks() or getVideoTracks()
      //       // var track = stream.getTracks()[0];  // if only one media track
      //       // console.log(">>>>streamstream>>>>"+track.enabled);
      //       // // track.stop();
      //       // track.enabled = false;
      //        console.log(stream.getTracks()[0].enabled + ">>>>>>>webcam");
      //       console.log("localStream.stream" + stream.getVideoTracks()[0]);
      //       console.log(stream.mediaStreamTrack + "...localtrack");
      //       console.log(stream.mediaStreamTrack.stop() + "...localtrack");
      //       stream.getTracks().forEach(function(track) {
      //         // track.setEnabled(false);
      //         // stream.mediaStreamTrack.enabled = !stream.mediaStreamTrack.enabled;
      //         // alert(">>>>track>>>>"+track);
      //           track.enabled = !track.enabled;

      //           track.stop();
      //           // stream =  null;s
                
      //         });
      //   },
      //   function(error){
      //       console.log('getUserMedia() error', error);
      //   });
      // console.log("localStream.stream" + localStream.stream.getVideoTracks()[0]);
      // localStream.stream.getTracks().forEach((track) => {
      //   track.stop();
      // });

      // const video = document.querySelector('#video-call clinician-video');
      // localStream.stream.getVideoTracks()[0].stop();
      // video.src = '';
      //   stream.getTracks().forEach(function(track) {
      //     if (track.readyState == 'live' && track.kind === 'video') {
      //         track.stop();
      //     }
      // });

    // navigator.mediaDevices.getUserMedia({video: true, audio: false})
    // .then(mediaStream => {
    //   const stream = mediaStream;
    //   const tracks = stream.getTracks();
    //   tracks.forEach(track => track.stop())
    //   // tracks[0].stop;
    // });
  // var r = confirm("Are you really want to Quit??");
  // if (r == true) {
  if (room != 0) {
    room.disconnect();
  }
  return ATList;
  // }
}

function startRecord() {
  // To start recording
  room.startRecord(function (result, error) {
    //document.getElementById("multi_record").innerText= "Hide Filter";

    if (result.result == 0) {
      // Recording started
      recording = 1;
      //   $("#multi_record").html("start");
      // var elem = document.getElementById("recording");
      // elem.classList.remove("hide");

      var elem = document.getElementById("stopRecord");
      elem.classList.remove("hide");
      var element = document.getElementById("startRecord");
      element.classList.add("hide");
    }
    // console.log(result);
    // console.log(error);

  });

}
// Notification recording started to all


function stopRecord() {
  // To stop recording
  room.stopRecord(function (result, error) {
    if (result.result == 0) {
      // Recording stopped
      // var elem = document.getElementById("recording");
      // elem.classList.add("hide");

      var elem = document.getElementById("stopRecord");
      elem.classList.add("hide");
      var element = document.getElementById("startRecord");
      element.classList.remove("hide");
    }
    //  console.log(result);
    //console.log(error);
  });
}


function extendcall() {
  // To extend Session
  room.extendConferenceDuration(function (message) {
    if (message.result == 0) {
      var extended_minutes = message.extendedTime;
    }
  });

}

// Notification recording stopped to all
// room.addEventListener( "room-record-off", function(event) {
//   // Recording stopped, Update UI
//   // event.message.moderatorId = Moderator who stopped recording.
//   alert(event);
// });




// $(document).on("click", "div.vcx_bar", function (e) {
//   $(this).parent().parent().toggleClass("fullScreen");
// });

// $(document).on("click", ".nav-tabs li a", function (e) {
//   $(document).find(".nav-tabs li").removeClass("active");
//   $(this).parent().addClass("active");
//   $(document).find("div.tab-pane").removeClass("active");
//   var activeDivId = $(this).attr("href");
//   $(activeDivId).addClass("active");
// });

// $(document).on("click", "#sendText", function (e) {
//   var rawText = $("#textArea").val();
//   var text = username + ": " + rawText;
//   $("#textArea").val("");
//   localStream.sendData({ textMessage: text });
// });

// $(document).on("click", "#user_radio", function (e) {
//   $(document).find(".user_select_div").show();
// });

// $(document).on("click", "#all_user_radio", function (e) {
//   $(document).find(".user_select_div").hide();
// });
// window.setTimeout(function(){
//   alert("121331");
//   $("#changelink").contents().find(".webgl-content").css({"position":"absolute", "top": "0",
//   "left": "0",   "right": "0",  "bottom": "0"});
// });
